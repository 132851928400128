import { version } from './version';

export const environment = {
  BASE_URL: 'https://decsantos.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3548500',
  schema: 'pbi_dte_santos',
  groupId: '54c00843-8136-428a-b9a4-7c6355ca50d7',
  analiseGeral: 'e009c48e-a3db-4a90-b8b7-332a9ef0de1d',
  analiseUsuarios: '16ee9b5e-99ef-4759-9535-8bf9598c0451',
  analiseComunicados: '531249ca-7492-4d49-af44-bb96cb9d43dd',
  analiseProcurador: '1db1fb06-566c-4c51-8d07-1d01a6fefcea',
  analiseEventos: '9baf05ff-9928-43ce-9e14-38ddfbabafa3',
  analiseContribuintes: '319cae44-024d-4f65-91f0-11d52986d3fd'
};
